import {memo} from 'react';
import { Button } from '@material-ui/core';
import { auth, provider } from './firebase';
import './Login.css'

function Login() {
    const signIn = () => {
        auth.signInWithRedirect(provider).catch(e => alert(e.message))
    }

    return (
        <div className="login">
            <div className="login__container">
                <link rel="icon" type="image/png" href="%PUBLIC_URL%/logo.png" />
                <div className="login__text">
                    <img src='logo.png' width={100} style={{marginBottom: 30}}/>
                    <p></p>
                    <h1>Log in bij Communitie</h1>
                    <p>En ga buiten voetballen met mensen uit de buurt in Kortrijk</p>
                </div>
                <Button onClick={signIn}>
                    Log in met Google
                </Button>
            </div>
        </div>
    )
}

export default memo(Login)